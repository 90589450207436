import {
  IconArrowRight,
  Link,
  Theme
} from "@life-without-barriers/react-components"

import React from "react"

const { youthFull } = Theme

export interface Props {
  policyStatementUrl: string
}

const ChildProtectionPolicyTile = ({ policyStatementUrl }: Props) => (
  <div className="policy-tile br2 lwb-shadow bg-white overflow-hidden">
    <div className="bg-lwb-theme-medium pt2 pb1" />
    <div className="pa3 pa4-ns">
      <h3 className="ts-display-5 fw6 color-lwb-theme-medium tc">
        Child protection policy
      </h3>
      <div className="mt3 copy tc">
        <p>
          We are committed to the safety of children across Australia. Read our
          child protection policy.
        </p>
      </div>
      <div className="z-1 mt3 fw6 flex items-baseline justify-content color-lwb-theme-medium">
        <Link
          to={policyStatementUrl}
          title={"Open Policy PDF"}
          className="center no-underline mt2"
        >
          Open policy{" "}
          <IconArrowRight
            height="8"
            width="5"
            color={youthFull.medium}
            className="ml2"
          />
        </Link>
      </div>
    </div>
  </div>
)

export default ChildProtectionPolicyTile
