import {
  Box,
  BreadcrumbLink,
  BreadcrumbStyle,
  CTALink,
  ContactUsEmailLink,
  Container,
  Heading,
  Helmet,
  Layout,
  Link,
  PreStyledComponents,
  Row,
  Section,
  StandardButton,
  Theme,
  YouTubeVideo
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import {
  Site,
  contentTypes as ct,
  getGatsbyImage
} from "@life-without-barriers/gatsby-common"

import BannerWePutChildrenFirst from "../../../components/our-approach/BannerWePutChildrenFirst"
import ChildProtectionPolicyTile from "../../../components/our-approach/ChildProtectionPolicyTile"
import React from "react"
import { graphql } from "gatsby"

const { GridWrapper } = PreStyledComponents
const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerYoungGirlImage: IGatsbyImageData
    bannerPinkBlastImage: IGatsbyImageData
    andyImage: IGatsbyImageData
    eveImage: IGatsbyImageData
    fionaImage: IGatsbyImageData
    samImage: IGatsbyImageData
    pdfCover: IGatsbyImageData
    pageResources: ct.PageResources
  }
}

const GreyStanceText = () => (
  <div className="db dn-m dn-l bg-lwb-grey-xxx-light pv4 ph4 nt2">
    <div className="f5 fw6 center tc tracked">
      Our stance on child safety and wellbeing
    </div>
  </div>
)

const WePutChildrenFirstPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerYoungGirlImage,
    bannerPinkBlastImage,
    andyImage,
    eveImage,
    fionaImage,
    samImage,
    pdfCover,
    pageResources
  }
}: Props) => {
  const childProtectionPolicyStatement = ct.unsafeFindPageDocumentByTitle(
    pageResources,
    "Child Protection Policy Statement"
  )
  const wePutChildrenFirstGuide = ct.unsafeFindPageDocumentByTitle(
    pageResources,
    "We Put Children First Booklet"
  )
  const bookImages = [
    { image: andyImage, altText: "Book Cover - Andy learns the undies rule" },
    {
      image: eveImage,
      altText: "Book Cover - Eve listens to her feelings"
    },
    {
      image: fionaImage,
      altText: "Book Cover - Fiona finds five heroes"
    },
    {
      image: samImage,
      altText: "Book Cover - Sam the safe explorer"
    }
  ]
  return (
    <div>
      <Helmet
        title={`We Put Children First | ${siteMetadata.title}`}
        description="We Put Children First is our commitment to the safety and wellbeing of children."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerYoungGirlImage}
      />
      <Layout theme={youthFull}>
        <BannerWePutChildrenFirst
          youngGirlImage={bannerYoungGirlImage}
          bannerPinkBlastImage={bannerPinkBlastImage}
        />
        <GreyStanceText />
        <Section background="bg-hex-image">
          <Container>
            <Row className="flex-row flex-wrap justify-between">
              <Box className="w-100 w-60-l">
                <BreadcrumbLink
                  breadcrumbStyle={BreadcrumbStyle.whiteBackground}
                  className="pb3 tc tl-l"
                  items={[
                    {
                      to: "/",
                      text: "Our approach",
                      title: "Return to parent page"
                    },
                    {
                      to: "..",
                      text: "Child, youth and family",
                      title: "Return to main page"
                    }
                  ]}
                />
                <Heading size={2} className="ma0 ts-display-4 pb4 fw7">
                  Every day, Life Without Barriers offers support and care to
                  children. We want every one of those children to feel as safe
                  and respected as they should.
                </Heading>
                <div>
                  We Put Children First is our commitment to the safety and
                  wellbeing of children. All staff and carers are required to be
                  a part of this commitment, to recognise the difference between
                  acceptable and unacceptable behaviours and understand the
                  importance of speaking up about child safety concerns.
                </div>
              </Box>
              <Box className="w-100 w-30-l w-60-m center-m">
                <ChildProtectionPolicyTile
                  policyStatementUrl={childProtectionPolicyStatement.file.url}
                />
              </Box>
            </Row>
            <Row>
              <Box className="w-100 pt4">
                <YouTubeVideo
                  className="mv4"
                  videoId="dPkF8ZrkzLg"
                  title="We Put Children First at Life Without Barriers"
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>
                  We&apos;re talking about child sexual abuse
                </Heading>
                <p className="w-100 w-80-l fw7 copy-lrg">
                  We&apos;re not afraid to say it directly - child sexual abuse
                  is a risk in an organisation like ours. One of the ways to
                  guard against child sexual abuse is to talk about it openly.
                  So while we understand it&apos;s a difficult topic, we&apos;re
                  willing to confront it head on.
                </p>
                <Heading size={3} className="color-lwb-black">
                  We&apos;re talking with staff and carers
                </Heading>
                <p>
                  Staff members and carers in the Life Without Barriers
                  community must act in a way that prioritises the wellbeing of
                  children at all times. To do this, we are aware of the
                  characteristics of respectful relationships with children,
                  child sexual abuse, how offenders operate, signs of possible
                  child abuse, and how to respond to disclosures.
                </p>
                <p>
                  Life Without Barriers staff and carers complete mandatory
                  training that explores these topics in detail. We have a range
                  of educational resources and practice reforms, and will
                  continue to expand these resources as we learn more about how
                  to keep children safe.
                </p>
                <YouTubeVideo
                  className="mv4 mv5-ns"
                  videoId="smbMs7H9yfM"
                  title="We Put Children First - Understanding Child Sexual Abuse"
                />
                <Heading size={3}>
                  We&apos;re talking with children and young people
                </Heading>
                <p>
                  It&apos;s not enough to educate our staff and carers about
                  child sexual abuse. We also need to talk to children in an
                  age-appropriate manner about sexual abuse. At Life Without
                  Barriers, we&apos;re using the SAFE Book Series (developed by
                  the NSW Children&apos;s Guardian) to talk with children about
                  their safety. The books support children&apos;s rights and
                  explore protective behaviours that can help keep them safe.
                </p>
                <GridWrapper className="flex flex-wrap justify-center pv4">
                  {bookImages.map((book, i) => (
                    <div key={i} className="w-100 w-50-m w-25-l">
                      <GatsbyImage
                        className="ma2"
                        image={getGatsbyImage(book.image)}
                        alt={book.altText}
                      />
                    </div>
                  ))}
                </GridWrapper>
                <Heading size={3}>
                  We&apos;re talking with our community
                </Heading>
                <p>
                  As a community, we have a collective responsibility to protect
                  children from abuse and harm, and knowledge sharing is a
                  practical way to achieve this. Many researchers have
                  contributed to our knowledge of child safety and wellbeing,
                  and we are now sharing our knowledge and practice with other
                  organisations that work with children.
                </p>
                <Heading size={3}>
                  The 1989 Convention on the Rights of the Child
                </Heading>
                <p>
                  The NSW Advocate for Children and Young People have developed
                  an educational video on the 1989 Convention on the Rights of
                  the Child (CRC). The CRC binds governments to respect, protect
                  and fulfil the political, economic, social and cultural rights
                  of every child.
                </p>
                <CTALink
                  to={"//www.youtube.com/watch?v=S25L4jllAng"}
                  className="mv3 mv3-ns"
                  text="Watch the video"
                  target="_blank"
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <GridWrapper className="flex flex-wrap flex-nowrap-ns justify-center">
                  <div className="w-100 w-50-ns lwb-shadow br2 bg-lwb-white mh3">
                    <div className="pa3 pa4-ns">
                      <Heading
                        size={3}
                        className="color-lwb-theme-medium"
                        topMargin="none"
                      >
                        Contact us
                      </Heading>
                      <p className="w-80-l">
                        For more information about our child safety approach
                        contact Melinda Clarke at
                      </p>
                      <ContactUsEmailLink
                        theme={youthFull}
                        text="melinda.clarke@lwb.org.au "
                        link="mailto:melinda.clarke@lwb.org.au"
                      />
                    </div>
                  </div>
                  <div className="w-100 w-50-ns lwb-shadow br2 bg-lwb-white mh3 mt3 mt0-ns">
                    <div className="pa3 pa4-ns">
                      <div className="flex">
                        <div className="dn db-l pl3">
                          <GatsbyImage
                            image={getGatsbyImage(pdfCover)}
                            alt="We put children first guide cover"
                          />
                        </div>
                        <div className="w-100 w-70-l pl4-l">
                          <Heading size={3} className="color-lwb-theme-medium">
                            Download the Guide
                          </Heading>
                          <p>
                            Find more information about how we work in our We
                            Put Children First PDF guide.
                          </p>
                          <Link
                            to={wePutChildrenFirstGuide.file.url}
                            title={`Download`}
                            className="dib mt3 no-underline"
                          >
                            <StandardButton theme={youthFull}>
                              Download PDF
                            </StandardButton>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerYoungGirlImage: file(
      relativePath: { regex: "/banner-young-girl.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    bannerPinkBlastImage: file(
      relativePath: { regex: "/banner-bg-wide.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1800, quality: 90)
      }
    }
    andyImage: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 344, quality: 90)
      }
    }
    eveImage: file(relativePath: { regex: "/eve.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 344, quality: 90)
      }
    }
    samImage: file(relativePath: { regex: "/sam.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 344, quality: 90)
      }
    }
    fionaImage: file(relativePath: { regex: "/fiona.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 344, quality: 90)
      }
    }
    pdfCover: file(relativePath: { regex: "/we-put-children-pdf-cover.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 120, quality: 90)
      }
    }

    pageResources: allContentfulPageResources(
      filter: { title: { eq: "we-put-children-first" } }
    ) {
      edges {
        node {
          title
          pageDocuments {
            ...RemoteContentfulFileAsset
          }
        }
      }
    }
    childProtectionPolicyStatement: allFile(
      filter: { name: { eq: "Child-Protection-Policy-Statement" } }
    ) {
      edges {
        node {
          name
          relativePath
          publicURL
        }
      }
    }
  }
`

export default WePutChildrenFirstPage
