import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import {
  PreStyledComponents,
  media
} from "@life-without-barriers/react-components"

import { BgImage } from "gbimage-bridge"
import React from "react"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"
import styled from "styled-components"

const { Container } = PreStyledComponents

const WePutChildrenText = styled.div`
  font-size: 28px;
  line-height: 1;

  ${media.notSmall`
    font-size: 42px;    
  `}

  @media screen and (min-width: 64em) and (max-width: 80em) {
    text-align: left;
  }

  @media screen and (min-width: 80em) {
    padding-left: 4rem;
  }
`

const FirstText = styled.div`
  font-size: 92px;
  line-height: 1;
  margin-left: -5px;

  ${media.notSmall`
    font-size: 140px;  
  `}

  @media screen and (min-width: 64em) and (max-width: 80em) {
    text-align: left;
    margin-left: -10px;
  }

  @media screen and (min-width: 80em) {
    padding-left: 4rem;
    margin-left: -10px;
  }
`

const OurStance = styled.h1`
  @media screen and (min-width: 64em) and (max-width: 80em) {
    text-align: left;
  }
  @media screen and (min-width: 80em) {
    padding-left: 4rem;
  }
`

const WhiteLine = styled.div`
  margin-left: auto;
  margin-right: auto;

  ${media.medium`
    width: 55%;    
  `}

  ${media.large`
    width: 65%; 
  `}

  @media screen and (min-width: 64em) and (max-width: 80em) {
    width: 41%;
    text-align: left;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media screen and (min-width: 80em) {
    margin-left: 4rem;
  }
`

const WePutChildrenFirstLogo = () => (
  <div
    className="flex flex-column"
    aria-label="We Put Children First. Our stance on child safety and wellbeing."
  >
    <div className="p0 pl4-l mv2 mv5-ns" aria-hidden={true}>
      <WePutChildrenText className="w-100 ttu vera-mono color-lwb-white">
        We Put Children
      </WePutChildrenText>
      <FirstText className="first-text w-100 ttu vera-mono color-lwb-white tracked-tight ml-1">
        F<span className="color-lwb-black">1</span>
        RST
      </FirstText>
      <WhiteLine className="dn db-m db-l ba color-lwb-white mv3"></WhiteLine>
      <OurStance className="ma0 w-100 dn db-m db-l f5 fw8 color-lwb-white tracked">
        Our stance on child safety and wellbeing
      </OurStance>
    </div>
  </div>
)

interface Props {
  youngGirlImage: IGatsbyImageData
  bannerPinkBlastImage: IGatsbyImageData
}
const BannerWePutChildrenFirst = ({
  youngGirlImage,
  bannerPinkBlastImage
}: Props) => (
  <BannerWrapper image={getImage(bannerPinkBlastImage)}>
    <GreyShadow className="banner-inner ph3 ph4-ns">
      <Container className="relative pt3 pt5-ns pb4">
        <StyledCopy className="z-5 copy pt3 pb2 pv3-ns">
          <WePutChildrenFirstLogo />
        </StyledCopy>
        <StyledImage
          loading="eager"
          className="young-girl absolute-l top-0-l bottom-0-l w-70-l"
          imgStyle={{
            objectPosition: "bottom"
          }}
          style={{
            position: "absolute"
          }}
          title={""}
          image={getGatsbyImage(youngGirlImage)}
          alt={""}
        />
      </Container>
    </GreyShadow>
  </BannerWrapper>
)

const GreyShadow = styled.div`
  &:before {
    z-index: 10;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0) 5%
    );
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

const BannerWrapper = styled(BgImage)`
  background-size: inherit;
  background-repeat: no-repeat;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  left: -100%;
  position: relative;
  width: 300%;
  height: 100%;

  @media screen and (min-width: 48em) {
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  /* apply fix: ios 7 border-radius-bug */
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  &:before,
  &:after {
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;

    @media screen and (min-width: 64em) and (max-width: 80em) {
      background-position: 27% 50% !important;
    }

    @media screen and (min-width: 80em) and (max-width: 120em) {
      background-position: -365px 0px !important;
    }

    @media screen and (min-width: 120em) and (max-width: 130em) {
      /* background-position: left top !important; */
      background-position: -380px 0px !important;
    }

    @media screen and (min-width: 130em) {
      background-position: left top !important;
      width: 70% !important;
    }
  }

  .copy {
    text-align: center;
  }

  .banner-inner {
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }

  @media screen and (min-width: 80em) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: 0;
    width: 100%;

    /* unset fix : ios 7 border-radius-bug */
    -webkit-mask-image: unset;

    .copy {
      text-align: left;
    }
  }
`

const StyledCopy = styled.div`
  width: 100%;

  @media screen and (min-width: 80em) {
    width: 50%;
  }
`

const StyledImage = styled(GatsbyImage)`
  display: none !important;
  right: -60%;
  transform: translateX(-45%);
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);

  @media screen and (min-width: 80em) and (max-width: 130em) {
    transform: translateX(-55%);
  }

  @media screen and (min-width: 130em) {
    transform: translateX(-65%);
  }

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 5;
  }

  :before {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
    width: 10%;
    background: white;
    opacity: 1;
  }

  img {
    left: 1px !important; /* hack to remove transparent line on medium resolutions */
    max-width: none !important;
    object-position: top center !important;
  }

  /* IE hack so image isn't stretched */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      width: auto !important;
    }
  }

  @media screen and (min-width: 64em) {
    display: block !important;
  }
`
export default BannerWePutChildrenFirst
